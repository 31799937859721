import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { taskComplete, taskVerified } from 'domain/actions/secretShopper.action';
import { Field, Form } from 'react-final-form';
import { Button } from 'primereact/button';
import DataContent from './dataContent';
import EntryContent from './entryContent';
import FarewellContent from './farewellContent';
import ImpressionContent from './impressionContent';
import OrderContent from './orderContent';
import ServiceContent from './serviceContent';
import StandardContent from './standardContent';
import { Dialog } from 'primereact/dialog';
import { requiredValidator } from 'components/form/validators';
import { TextAreaField } from 'components/form/fields';
import { groupBy } from 'lodash';
import SumReceiptWindow from '../sum.receipt.window';


export const ReportForm: React.FC = (props: any) => {
  const { current, taskVerified } = props;
  const [ show, setShow ] = useState(false);
  const [ showSum, setShowSum ] = useState(false);
  const [ option, setOption ] = useState<string>("");
  const [ data, setData ] = useState<any>(null);
  const [ val, setValues ] = useState<any>(null);

  useEffect(() => {
    if(!current) return;

    const _data = current;
    _data.questions = _data.responses.reduce((a:any, v:any) => (
      { 
        ...a, 
        [v.questionId]: {
          question: 
            v.response === 'true' || v.response === 'false' 
              ? v.response === 'true'
              : v.response,
          comment: v.comment,
          balls: v.balls,
          isLike: !!+v.balls  
        }
      }
    ), {})
    
    const group = groupBy(_data.responses, (item) => item.services)
    
    if(Object.keys(group).length){
      const waiter = group['waiter']?.length ? group['waiter'].length : 0;
      const self = group['self']?.length ? group['self']?.length : 0;

      if(waiter > self) {
        setOption('waiter')
      }
      else if(waiter < self) {
        setOption('self')
      }
    }

    setData(_data)
  },[current]);

  const onSubmit = (values: any) => {
    setValues(values)
    setShowSum(true)
  }

  const handleFailReport = (event: any) => {
    event.preventDefault();
    setShow(true)
  }

  const onSubmitReport = (values: any) => {
    taskVerified(current.id, values.comment)
  }

  const formatQuestion = () => {
    return current && current.report ? 
      current.report.questions
        .filter( (q: any) => q.option === option)
        .map( (i: any) => i.question) 
    : []
  }

  return (
    <>
      <Form
        onSubmit={onSubmit}
        initialValues={data ? data : null}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="p-col-12 p-p-0">
            <DataContent questions={formatQuestion()} disabled />
            <EntryContent questions={formatQuestion()} disabled />
            <OrderContent questions={formatQuestion()} disabled />
            <ServiceContent questions={formatQuestion()} disabled />
            <StandardContent questions={formatQuestion()} disabled />
            <FarewellContent questions={formatQuestion()} />
            <ImpressionContent questions={formatQuestion()} disabled />

            {current?.state !== 'confirm' && (
              <div className="p-mt-1 p-col-12 p-d-flex p-flex-wrap p-jc-between">
                <Button className="p-col-12 p-md-5 p-xl-4 p-button-danger" label="Не прошел" onClick={handleFailReport}/>
                <Button className="p-col-12 p-md-5 p-xl-4 p-button-success" label="Принять" type="submit"/>
              </div>
            )}
            
          </form>
        )}
      />

      <Dialog visible={show} style={{minWidth: '383px'}} onHide={()=>setShow(false)}>
        <Form
          onSubmit={onSubmitReport}
          styles={{minWidth: '320px'}}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="p-col-12 p-p-0">
              <Field
                validate={requiredValidator}
                name="comment"
                label="Комментарий отказа:"
                render={TextAreaField}
              />
              <div className="p-mt-1 p-col-12 p-d-flex p-flex-wrap p-jc-end">
                <Button className="p-col-12 p-md-5 p-xl-4 p-button-success" label="Отправить" type="submit"/>
              </div>
            </form>
          )}
        />
      </Dialog>

      <SumReceiptWindow id={val?.id} show={showSum} setShow={setShowSum} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  current: state.secretShopper.moderation.current,
})

export default connect(
  mapStateToProps, 
  { taskComplete, taskVerified }
)(ReportForm);