import React from "react";
import { Norr } from "components/norr";
import { INorr } from "domain/types/norr.type";
import { SeverityType } from "components/norr";
import { connect } from "react-redux";

export const NorrComponent: React.FC<INorr> = (props: INorr) => {
  const { show, severity, message } = props;
  console.log(show, "show")
  return <Norr show={show} severity={severity as SeverityType} message={message} />
}

const mapStateToProps = (state: any) => ({
  show: state.norr.show,
  severity: state.norr.severity, 
  message: state.norr.message,
})

export default connect(mapStateToProps)(NorrComponent);