import React from "react";
import { connect } from "react-redux";

import { Form } from "react-final-form";
import { initialValuesQr } from "./initialValues";
import CreateQRForm from "./create.qr.form";
import { error } from "domain/actions/norr.action";
import { createOrder } from "domain/actions/billing.action";

interface IProps {
  setType: (type: string) => void;
}

export const ReceiptLayout: React.FC<IProps> = (props: any) => {
  const {setType, questionnaire, error, createOrder} = props;

  const onSubmit = (values: any) => {
    if(!questionnaire) return error("Анкета не выбрана");

    createOrder({
      restaurantId: values.restaurant.id,
      amount: values.sum,
      // amount: 1,
      typePayment: "pay_acc",
      quantityShoppers: values.quantityShoppers,
      questionnaire: questionnaire
    });
  }

  return (
    <div className="p-col-12 p-p-0">
      <h4>Оплата по расчетному счету</h4>
      <Form
        onSubmit={onSubmit}
        initialData={initialValuesQr}
        styles={{minWidth: '320px'}}
        render={({ handleSubmit, submitting, values }) => (
          <CreateQRForm handleSubmit={handleSubmit} submitting={submitting} values={values} setType={setType}/>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  questionnaire: state.secretShopper.schemas.current 
})

export default connect(mapStateToProps, { error, createOrder })(ReceiptLayout);