// import BillingController from '../controllers/Billing.controller';
import { call, fork, put, select, takeEvery } from 'redux-saga/effects'
import * as types from 'domain/types/billing.type';
import * as actions from "domain/actions/billing.action";
import * as constants from "domain/constants/billing.constant";
import * as reducers from "domain/reducers/billing.reduce";
import { Result } from 'domain/types/static.type';
import { NorrController } from 'domain/controllers/Response.controller';
import { instanceBilling } from 'domain/controllers/axios.controller';
import ApiController from 'domain/controllers/Api.controller';
import { showWindow } from 'domain/actions/billing.action';
import {showWindow as showWindowRestaurant} from "domain/actions/restaurants.action";

// const api = new BillingController();
const api = new ApiController();
const norr = new NorrController();

export async function getOrdersFetch(): Promise<Result<any>>{
  return await api.get(`/billing/order`);
}

export async function getOrdersArchiveFetch(): Promise<Result<any>>{
  return await api.get(`/billing/order/archive`);
}

export async function getOrderFetch(id: string): Promise<Result<any>>{
  return await api.get(`/billing/order/${id}`);
}

export async function buyCouponFetch(data: types.IBuyCouponResponse): Promise<Result<string>> {
  return await instanceBilling.post(`/coupons/buy`, data)
}

export async function createOrderFetch(data: types.ICreateOrderResponse): Promise<Result<any>>{
  return await api.post(`/billing/order`, data)
}

export async function createOrderForAccountFetch(data: any): Promise<Result<any>>{
  return await api.post(`/billing/order/account`, { data, accountId: data.accountId })
}

export async function sendOrderFetch(qrId: string, data: {phone: string, email: string}): Promise<Result<any>>{
  return await api.post(`/billing/order/${qrId}/send`, data)
}

export async function activeCouponFetch(couponId: string): Promise<Result<any>> {
  return await api.put(`/billing/order/active`, {couponId})
}

export async function archiveCouponFetch(couponId: string): Promise<Result<any>> {
  return await api.put(`/billing/order/archive`, {couponId})
}

function* getOrders(): any{
  yield put(actions.reqBilling(true))

  const response = yield call(getOrdersFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setOrders(response.value))
  })

  yield put(actions.reqBilling(false))
}

function* getOrdersArchive(): any{
  yield put(actions.reqBilling(true))

  const response = yield call(getOrdersArchiveFetch)

  yield call(norr.processing, response, function *(){
    yield put(actions.setOrders(response.value))
  })

  yield put(actions.reqBilling(false))
}

function* getOrder(action:any): any{
  yield put(actions.reqBilling(true))

  const id = action.payload.id;

  const response = yield call(getOrderFetch, id)

  yield call(norr.processing, response, function *(){
    yield put(actions.setOrder(response.value))
  })

  yield put(actions.reqBilling(false))
}

function* buyCoupons(action: any): any{
  const data = action.payload;

  data.accountId = "ab4d20a8-50a4-4f33-93c2-b95c88f6ed48";

  const {response} = yield call(buyCouponFetch, data)

  yield call(norr.processing, response, function *(){
    yield console.log(response.value)
  })
}

export function* createOrder(action: any): any{
  const data = action.payload;

  data.quantityShoppers = +data.quantityShoppers

  const response = yield call(createOrderFetch, data);
  yield call(norr.processing, response, function *(){
    if(data.typePayment === 'qr') {
      yield put(actions.setLink(response.value.qrId))
      yield put(showWindow('send_link', true))
    }else {
      yield put(showWindow('new', false))
    }

    yield call(getOrders)
  })
}

export function* createOrderForAccount(action: any): any{
  const data = action.payload;

  const response = yield call(createOrderForAccountFetch, data);
  yield call(norr.processing, response, function *(){
    yield put(showWindowRestaurant('create_task', false))
  })
}

export function* sendOrder(action: any): any{
  const data = action.payload;
  const qrId = yield select(reducers.getQr);

  const response = yield call(sendOrderFetch, qrId, data);
  yield call(norr.processing, response, function *(){
    yield put(showWindow('send_link', false))
  })
}

export function* activeCoupon(action: any): any{
  const couponId = action.payload.id;

  const response = yield call(activeCouponFetch, couponId);
  yield call(norr.processing, response, function *(){
    yield call(norr.success, "Купон успешно активирован")
    yield put(actions.getOrder(couponId))
    yield call(getOrders)
  })
}

export function* archiveCoupon(action: any): any{
  const couponId = action.payload.id;

  const response = yield call(archiveCouponFetch, couponId);
  yield call(norr.processing, response, function *(){
    yield call(getOrders)
  })
}

export function* watch() {
  yield takeEvery(constants.BILLING_SAGA_GET_ORDERS, getOrders)
  yield takeEvery(constants.BILLING_SAGA_GET_ARCHIVE_ORDERS, getOrdersArchive)
  yield takeEvery(constants.BILLING_SAGA_GET_ORDER, getOrder)
  yield takeEvery(constants.BILLING_SAGA_BUY_COUPONS, buyCoupons)
  yield takeEvery(constants.BILLING_SAGA_CREATE_ORDER, createOrder) 
  yield takeEvery(constants.BILLING_SAGA_CREATE_ORDER_FOR_ACCOUNT, createOrderForAccount)
  yield takeEvery(constants.BILLING_SAGA_SEND_ORDER, sendOrder)
  yield takeEvery(constants.BILLING_SAGA_ACTIVE_COUPONS, activeCoupon)
  yield takeEvery(constants.BILLING_SAGA_ARCHIVE_COUPONS, archiveCoupon)
}

export default function* sagas() {
  yield fork(watch)
}