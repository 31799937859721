import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { 
  showWindow, 
  createDishes, 
  getDishesRestaurant 
} from 'domain/actions/restaurants.action';

import { Dialog } from "primereact/dialog";
import { Field, Form } from "react-final-form";
import { Button } from "primereact/button";
import { IDishes } from "domain/types/restaurants.type";
import { composeValidators, requiredValidator } from "components/form/validators";
import { MultiSelectField, SelectField, TextAreaField, TextField } from "components/form/fields";
import { showError } from "domain/actions/norr.action";
import SingleUploadImage from "components/upload/single.upload";

export const AddDishesWin: React.FC = (props: any) => {
  const { getDishesRestaurant } = props;
  const { visible, categories, kitchens, tags } = props;
  const { showWindow, createDishes } = props;

  const [ file, setFile ] = useState(0);
  let refGallery = useRef<any>(null);

  useEffect(() => getDishesRestaurant(), []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if(!visible) setFile(0)
  }, [visible])

  const handleClose = () => {
    showWindow('addDishes', false)
    setFile(0)
  };
  const onSubmit = (values: IDishes) => {
    createDishes( values, file )
  };

  const dataForm = {
    name: "",
    description: "",
    photo: "",
    price: "",
    grams: "",
    categoryId: null,
    kitchenId: null,
    tagIds: []
  }

  return (
    <Dialog header="Создание блюда" visible={visible} className="p-col-12 p-p-0 p-md-10 p-lg-8" onHide={handleClose}>
      <Form
        onSubmit={onSubmit}
        initialValues={dataForm}
        render={({ handleSubmit }) => (
          
          <form onSubmit={handleSubmit}>
            <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap">
              <div className="p-col-12 p-md-6 p-p-0">
                <div className="p-col-12">
                  <SingleUploadImage ref={refGallery} label={"Загрузить фотографию"} onSelectFIle={setFile} file={file}/>
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="name" label="Наименование:" render={TextField}
                  />
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="description" label="Описание:" render={TextAreaField}
                  />
                </div>
              </div>

              <div className="p-col-12 p-md-6 p-p-0">

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="price" label="Цена:" render={TextField}
                    settings={{ placeholder: "Цена за блюдо", keyfilter: /[\d\/]+$/ }}
                  />
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="kitchenId" label="Категория меню:" render={SelectField}
                    settings={{
                      options: kitchens,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите категорию"
                    }}
                  />
                </div>

                <div className="p-col-12">
                  <Field
                    validate={composeValidators(requiredValidator)}
                    name="categoryId" label="Категория:" render={SelectField}
                    settings={{
                      options: categories,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите категорию"
                    }}
                  />
                </div>

                <div className="p-col-12">
                  <Field
                    name="tagIds" label="Теги:" render={MultiSelectField}
                    settings={{
                      options: tags,
                      optionLabel: "name",
                      filter: true,
                      showClear: true, 
                      filterBy: "name",
                      placeholder: "Выберите необходимые теги",
                      filterPlaceholder: "Поиск по наименованию",
                      emptyFilterMessage: "Нет данных",
                      maxSelectedLabels: 2,
                      resetFilterOnHide: true,
                    }}
                  />
                  
                </div>

              </div>
            </div>
              
              <div className="p-col-12 p-p-0 p-d-flex p-flex-wrap p-mt-1 p-mb-2 p-jc-between">
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Отмена" className="p-col-12 p-button-raised p-button-plain p-button-text " onClick={handleClose}/>
                </div>
                <div className="p-col-12 p-md-6 p-xl-3">
                  <Button label="Создать" className="p-col-12 p-button-raised p-button-success "/>
                </div>
              </div>
            </form>
        )}
      />
    </Dialog>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.restaurants.windows.addDishes,
  categories: state.restaurants.kitchen.categories,
  kitchens: state.restaurants.kitchen.nationality,
  tags: state.restaurants.kitchen.tags
})

export default connect(mapStateToProps, { 
  showWindow, createDishes, showError, getDishesRestaurant
})(AddDishesWin);