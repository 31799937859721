import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import { setFilters, getRestaurants, showWindow, setPageRestaurants } from 'domain/actions/restaurants.action';

import { Field, useForm } from 'react-final-form';
import { aroundQuantityValidator, composeValidators, requiredValidator } from 'components/form/validators';
import { SelectField, TextAreaField, TextField } from 'components/form/fields';

import { Button } from 'primereact/button';
import { showWindowSchema } from 'domain/actions/secretShopper.action';
import ListRestaurantWindows from 'pages/views/private/restaurants/windows/list.restaurant.window';

export const CreateQRForm: React.FC<any> = (props: any) => {
  const [ selected, setSelected ] = useState(null);
  const { cities } = props;
  const { setFilters, getRestaurants } = props;
  const { submitting, handleSubmit, setType, showWindow, setPageRestaurants } = props;

  const {
    showWindowSchema,
    selectedQuestionnaire
  } = props;
  const form = useForm ();
  const values = form.getState().values;

  useEffect(() => {
    if(values.city) {
      setFilters([values.city], [], ['active'])
      setPageRestaurants(1)
      onSelected(null)
      getRestaurants()
    };
  }, [values.city, getRestaurants, setFilters]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { form.change('quantityShoppers', 25) }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(values.restaurant)
      form.change('maxPrice', values.restaurant?.addInfo?.averageReceipt)
  }, [form, values])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!values.maxPrice) return form.change('sum', 0);
    if(Object.keys(values).length && typeof values.maxPrice) {
      const summary = values.maxPrice.split("-").filter( (sum:string) => sum !== "");
      const length = summary.length;
      const _reduce = summary.reduce((sum:string, current:string) => +sum + +current, 0);
      
      if(length)
        form.change('sum', _reduce/length * 3)
    }
  }, [form, values.maxPrice])// eslint-disable-line react-hooks/exhaustive-deps

  const handleQuestionnaire = (e: any) => {
    e.preventDefault();

    showWindowSchema("schemaQuestionnaire", true);
  }
  const handleSelect = () => showWindow('list_restaurants', true);
  const onSelected = (value: any) => {
    setSelected(value)
    form.change('restaurant', value )
  }; 

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="p-mt-2 p-mb-2">
          <Field
            name="city" label="Город"
            render={SelectField}
            settings={{
              options: cities,
              optionLabel: "name",
              filter: true,
              showClear: true, 
              filterBy: "name",
              placeholder: "Выберите город",
              emptyFilterMessage: "Совпадений не найдено"
            }}
          />
        </div> 

        {values.city && (
          <>
              <div className="p-mt-2 p-mb-2">
                <div className="p-inputgroup">
                  <div style={{ width: '100%'}}>
                    <Field
                      name="restaurant.name"
                      label="Заведение"
                      render={TextAreaField}
                      settings={{
                        disabled: true,
                      }}
                    />
                  </div>
                  <Button icon="pi pi-search-plus" className="p-button-success" onClick={(e) => {
                    e.preventDefault();
                    handleSelect();
                  }} />
                </div>
              </div>
            {/* <div className="p-mt-2 p-mb-2">
              <Field
                validate={composeValidators(requiredValidator)}
                name="restaurant" label="Заведение"
                render={SelectField}
                settings={{
                  options: restaurants,
                  optionLabel: "name",
                  filter: true,
                  showClear: true, 
                  filterBy: "name",
                  placeholder: "Выберите заведение",
                  emptyFilterMessage: "Совпадений не найдено"
                }}
              />
            </div>   */}
            
            <div className='p-mt-3 p-mb-2'>
              <Field
                validate={composeValidators(requiredValidator, aroundQuantityValidator(1, 25))}
                name="quantityShoppers"
                label="Лимит участников"
                render={TextField}
                help="Количество пользователей которые могут принять участие в акции"
                settings={{
                  placeholder: "25",
                  keyfilter: 'int',
                }}
              />
            </div>

            <div className='p-mt-3 p-mb-2'>
              <Field
                validate={requiredValidator}
                name="maxPrice"
                label="Средний чек"
                render={TextField}
                settings={{
                  placeholder: "0",
                  keyfilter: 'int',
                  disabled: true
                }}
                icon={["fas", "ruble-sign"]}
              />
            </div>

            <div className='p-mt-3 p-mb-2'>
              <Field
                validate={ requiredValidator }
                name="sum" label="Сумма к оплате"
                render={TextField}
                settings={{
                  placeholder: "0",
                  disabled: true
                }}
              />
            </div>

            <div className='p-col-12 p-p-0'>
              <Button 
                label={ selectedQuestionnaire ? `Выбрано: ${selectedQuestionnaire.name}` : `Выбрать анкету`} 
                className="p-button-outlined p-button-secondary p-col-12" 
                onClick={handleQuestionnaire}
              />
            </div>
          </>
        )}
        

        <div className="p-col-12 p-p-0 p-mt-3 p-d-flex p-flex-wrap p-jc-between">
          <Button label="Назад" className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-outlined" onClick={(e)=>{
            e.preventDefault();
            setType(null)
          }} />
          <Button label="Сформировать" className="p-col-12 p-md-5 p-mt-1 p-mb-1 p-button-raised p-button-success" disabled={submitting} />
        </div>
      </form>

      <ListRestaurantWindows city={values.city ? values.city : null} defaultValue={selected} onSubmit={onSelected} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  cities: state.cities.cities,
  restaurants: state.restaurants.restaurants,
  selectedQuestionnaire: state.secretShopper.schemas.current 
})

export default connect(mapStateToProps, {
  setFilters, 
  getRestaurants, 
  showWindowSchema,
  showWindow,
  setPageRestaurants
})(CreateQRForm);