import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import {connect} from "react-redux";
import { currentMe } from "domain/actions/auth.action";

import { ProvideAuth } from "./config/ProvideAuth";

import { Auth } from "pages/views/public";
import { 
  Analytics, 
  Panel, 
  MainRestaurants, 
  SecretShopper, 
  Settings, 
  Users, 
  Billing,
  Notification,
  Questionnaires
} from 'pages/views/private';
import Restaurants from 'pages/views/private/restaurants/restaurant';
import BidMy from 'pages/views/private/restaurants/bidMy';
import SecretShopperBids from "pages/views/private/secretShopper/bids";
import SecretShopperTasks from "pages/views/private/secretShopper/tasks";
import SecretShopperReceipts from "pages/views/private/secretShopper/receipts";
import OrdersPage from "pages/views/private/billing/orders";
import Categories from "pages/views/private/restaurants/categories";
import BidHelps from "pages/views/private/restaurants/bidHelps";
import SubscribeNotificationPage from "pages/views/private/notification/subscribe.notification";
import Raffle from "pages/views/private/secretShopper/raffle";
import EmailNotificationPage from "pages/views/private/notification/email.notification";
import Stocks from "pages/views/private/restaurants/stocks";

function RoutesMap(props: any) {
  const { currentMe } = props;

  useEffect(() => currentMe(), [currentMe])

  return (
    <ProvideAuth>
      <Routes>

        <Route path="/" element={<Auth />} />
        <Route path="/panel" element={<Panel />}>
          <Route path="analytics" element={<Analytics />} />
          <Route path="users" element={<Users />} />
          <Route path="settings" element={<Settings />} />

          <Route path="notification" element={<Notification />} >
            <Route path="subscriptions" element={<SubscribeNotificationPage />} />
            <Route path="email" element={<EmailNotificationPage />} />
          </Route>

          <Route path="billing" element={<Billing />} >
            <Route path="orders" element={<OrdersPage />} />
          </Route>

          <Route path="restaurants" element={<MainRestaurants />} >
            <Route path="stocks" element={<Stocks />} />
            <Route path="all" element={<Restaurants />} />
            <Route path="bid_my" element={<BidMy />} />
            <Route path="bid_helps" element={<BidHelps />} />
            <Route path="menu" element={<Categories />} />
          </Route>

          <Route path="secret_shopper" element={<SecretShopper />}>
            <Route path="bids" element={<SecretShopperBids />} />
            <Route path="tasks" element={<SecretShopperTasks />} />
            <Route path="receipts" element={<SecretShopperReceipts />} />
            <Route path="questionnaires" element={<Questionnaires />} />
            <Route path="raffle" element={<Raffle />} />
          </Route>
        </Route>
        
      </Routes>
    </ProvideAuth>
  );
}

export default connect(null, {currentMe})(RoutesMap);