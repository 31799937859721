import { ICategory } from './../types/restaurants.type';
import * as constants from "domain/constants/secretShopper.constant";
import * as types from "domain/types/secretShopper.type";

export function getSecretShopperBids(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_BIDS,
  }
}

export function getSecretShopperBidById(id: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_BID_BY_ID,
    payload: { bidId: id }
  }
}

export function getRaffles(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_RAFFLES,
  }
}

export function getRaffleById(id: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_RAFFLE_BY_ID,
    payload: { raffleId: id }
  }
}

export function getSecretShopperTasks(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_TASKS,
  }
}

export function getSecretShopperTaskById(id: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_TASK_BY_ID,
    payload: { bidId: id }
  }
}

export function getTasksModeration(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_TASK_MODERATION,
  }
}

export function getSecretShopperModerationById(id: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_MODERATION_BY_ID,
    payload: { moderationId: id }
  }
}

export function getQuestionnaireByTaskId(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_QUESTIONNAIRE_BY_TASK_ID,
    payload: { taskId }
  }
}

export function getShopperCategories(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_CATEGORIES,
  }
}

export function setShopperCategories(categories: ICategory[]){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CATEGORY,
    payload: { categories: categories }
  }
}

export function getQuestions(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_QUESTIONS,
  }
}

export function createQuestion(data: types.IQuestion){
  return {
    type: constants.SECRET_SHOPPER_SAGA_CREATE_QUESTIONS,
    payload: { data }
  }
}

export function deleteQuestion(id: string, callback: () => void){
  return {
    type: constants.SECRET_SHOPPER_SAGA_DELETE_QUESTIONS,
    payload: { questionId: id, callback }
  }
}

export function updateQuestion(data: types.IQuestion){
  return {
    type: constants.SECRET_SHOPPER_SAGA_UPDATE_QUESTIONS,
    payload: { data }
  }
}

export function clearQuestion(){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_QUESTION
  }
}

export function getSchemas(){
  return {
    type: constants.SECRET_SHOPPER_SAGA_GET_SCHEMAS,
  }
}

export function createSchema(data: types.ISchema){
  return {
    type: constants.SECRET_SHOPPER_SAGA_CREATE_SCHEMA,
    payload: { data }
  }
}

export function updateSchema(data: types.ISchema){
  return {
    type: constants.SECRET_SHOPPER_SAGA_UPDATE_SCHEMA,
    payload: { data }
  }
}

export function clearSchema(){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_CLEAR_CURRENT_SCHEMA
  }
}

export function createBid(values: any){
  return {
    type: constants.SECRET_SHOPPER_SAGA_CREATE_BID,
    payload: {
      restaurantId: values.restaurant.id,
      ownerId: values.owner.accountId,
      cost: values.cost,
      comment: values.comment
    }
  }
}

export function createTasks(values: {bidId: string, name: string, description: string}){
  return {
    type: constants.SECRET_SHOPPER_SAGA_CREATE_TASKS,
    payload: values
  }
}

export function setQuestions(questions: types.IQuestion[]){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_QUESTIONS,
    payload: { questions }
  }
}

export function setQuestion(question: types.IQuestion){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_QUESTION,
    payload: { question }
  }
}

export function setSchemas(schemas: types.ISchema[]){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_SCHEMAS,
    payload: { schemas }
  }
}

export function setSchema(schema: types.ISchema){
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_SCHEMA,
    payload: { schema }
  }
}

export function setSecretShopperBids(bids: Array<types.IBid>): types.BidsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_BIDS,
    payload: { bids }
  }
}

export function setCurrentBid(bid: types.IBid): types.BidsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_BID,
    payload: { bid }
  }
}

export function setSecretShopperRaffles(raffles: Array<types.IRaffle>): types.RafflesReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_RAFFLES,
    payload: { raffles }
  }
}

export function setCurrentRaffle(raffle: types.IRaffle): types.RafflesReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_RAFFLE,
    payload: { raffle }
  }
}

export function setSecretShopperTasks(tasks: Array<types.ITask>): types.TasksReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_TASKS,
    payload: { tasks }
  }
}

export function setCurrentTask(task: types.ITask): types.TasksReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_TASK,
    payload: { task }
  }
}

export function setTasksModeration(moderation: Array<types.IModeration>): types.ModerationReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_MODERATION,
    payload: { moderation }
  }
}

export function setModerationCurrent(moderation: types.IModeration): types.ModerationReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_REDUCE_SET_CURRENT_MODERATION,
    payload: { moderation }
  }
}

export function continueTask(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_CONTINUE_TASK,
    payload: { taskId }
  }
}

export function deactivateTask(taskId: string, message: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_DEACTIVATE_TASK,
    payload: { taskId, message }
  }
}

export function taskComplete(moderationId: string, sum: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_COMPLETE_TASK,
    payload: { moderationId, sum }
  }
}

export function taskVerified(id: string, comment: string){
  return {
    type: constants.SECRET_SHOPPER_SAGA_VERIFIED_TASK,
    payload: { id, comment }
  }
}

export function showWindowBid(window: string, isShow: boolean ): types.BidsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_BIDS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function showWindowQuestion(window: string, isShow: boolean ): types.QuestionsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_QUESTIONS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function showQuestion(id: string) {
  return {
    type: constants.SECRET_SHOPPER_SAGA_QUESTIONS_IS_SHOW,
    payload: { questionId: id }
  }
}

export function showWindowSchema(window: string, isShow: boolean ): types.SchemaReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_SCHEMAS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function showSchema(id: string) {
  return {
    type: constants.SECRET_SHOPPER_SAGA_SCHEMA_IS_SHOW,
    payload: { schemaId: id }
  }
}

export function deleteSchema(id: string) {
  return {
    type: constants.SECRET_SHOPPER_SAGA_SCHEMA_DELETE,
    payload: { schemaId: id }
  }
}

export function setPaymentReceipt(taskId: string){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_SET_RECEIPT,
    payload: {taskId}
  }
}

export function uploadReceipt(taskId: string, file: any){
  return { 
    type: constants.SECRET_SHOPPER_SAGA_UPLOAD_RECEIPT, 
    payload: { taskId, file } 
  }
}

export function showWindowTask(window: string, isShow: boolean ): types.TasksReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_TASKS_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function setUrlReceipt(url: string): types.TasksReduceTypes {
  return {
    type: constants.SECRET_SHOPPER_SET_URL_RECEIPT,
    payload: { url }
  }
}

export function getRequestRemuneration(taskId: string){
  return {
    type: constants.SECRET_SHOPPER_GET_REMUNERATION_BY_TASK,
    payload: { taskId }
  }
}

export function setRequestRemuneration(logging: any){
  return {
    type: constants.SECRET_SHOPPER_SET_REQUEST_REMUNERATION,
    payload: { logging }
  }
}

export function showWindowModeration(window: string, isShow: boolean ): types.ModerationReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_MODERATION_IS_SHOW,
    payload: { 
      typeWindow: window,
      isShowWindow: isShow
    }
  }
}

export function reqBids(isLoad: boolean): types.BidsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_BIDS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqRaffles(isLoad: boolean): types.RafflesReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_RAFFLE_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqTasks(isLoad: boolean): types.TasksReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_TASKS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqReceipts(isLoad: boolean): types.ReceiptsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_RECEIPTS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqModeration(isLoad: boolean): types.ModerationReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_MODERATION_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}

export function reqQuestionnaire(isLoad: boolean): types.QuestionsReduceTypes{
  return {
    type: constants.SECRET_SHOPPER_QUESTIONS_CHECKOUT_REQUEST,
    payload: { loading: isLoad }
  }
}